<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>IVR 신규등록</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">            
            <v-form ref="form" v-model="isFormValid" lazy-validation>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">코드</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_CODE" :rules="[formRules.MSG_CODE.required]" maxlength="100" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">한글명</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_HANGL_NM" :rules="[formRules.MSG_HANGL_NM.required]" maxlength="100" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">영문명</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_ENG_NM" :rules="[formRules.MSG_ENG_NM.required]" maxlength="100" outlined required></v-text-field>
                            </td>
                        </tr>


                        <tr>
                            <th><label class="is-required">MSG_TP_CODE</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_TP_CODE" maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">MSG_DESCR_CNTS</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_DESCR_CNTS"  maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">CNTR_USE_YN</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.CNTR_USE_YN"  maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">COM_MSG_HANGL_NM</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.COM_MSG_HANGL_NM"  maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">DOMN_USE_YN1</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.DOMN_USE_YN1" maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">DOMN_CNTS1</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.DOMN_CNTS1" maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">DOMN_USE_YN2</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.DOMN_USE_YN2"  maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">DOMN_CNTS2</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.DOMN_CNTS2" maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">MSG_GRD_CODE</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formIvrData.MSG_GRD_CODE" maxlength="100" outlined ></v-text-field>
                            </td>
                        </tr>
                                                                                                                                                                                                                                                
                    </tbody>
                </table>
            </v-form>
        </div>
        <div class="jh-dialog-footer">
            <v-btn class="jh-btn is-md is-main" @click="submit">저장</v-btn>
        </div>
    </div>
</template>

<script>
import api from "../../store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";

let jsonObj

export default {
  name: "MENU_E040110P01", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  props: {
    searchCustKey: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      common_code: [],
      mainVo: function() {
        this.MSG_CODE=''
        this.MSG_HANGL_NM=''
        this.MSG_ENG_NM=''
      },
      formIvrData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        MSG_CODE: {
          required: v => !!v || '코드' + this.formRulesSubfix
        },
        MSG_HANGL_NM: {
          required: v => !!(v  && (v+'').trim()!=='') || '한글명' + this.formRulesSubfix
        },
        MSG_ENG_NM: {
          required: v => !!(v  && (v+'').trim()!=='') || '영문명' + this.formRulesSubfix
        }
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    async initData(){
      this.$refs.form.resetValidation()
      this.formIvrData = new this.mainVo()

      let codeName = ["TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
    },
    async submit(){

      if(!this.$refs.form.validate()) return false

      let URLData = "/api/phone/ivr/regist";
      let HEADER_SERVICE = "phone.ivr.regist";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        MSG_CODE: this.formIvrData.MSG_CODE,
        MSG_HANGL_NM: this.formIvrData.MSG_HANGL_NM,
        MSG_ENG_NM: this.formIvrData.MSG_ENG_NM,
        USER_ID: this.userId,

        MSG_TP_CODE: this.formIvrData.MSG_TP_CODE,
        MSG_DESCR_CNTS: this.formIvrData.MSG_DESCR_CNTS,
        CNTR_USE_YN: this.formIvrData.CNTR_USE_YN,
        COM_MSG_HANGL_NM: this.formIvrData.COM_MSG_HANGL_NM,
        DOMN_USE_YN1: this.formIvrData.DOMN_USE_YN1,
        DOMN_CNTS1: this.formIvrData.DOMN_CNTS1,
        DOMN_USE_YN2: this.formIvrData.DOMN_USE_YN2,
        DOMN_CNTS2: this.formIvrData.DOMN_CNTS2,
        MSG_GRD_CODE: this.formIvrData.MSG_GRD_CODE,
      };

      //api 호출 부분
      await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE
            },
          })
          .then((response) => {
            if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {

              if(response.data.DATA[0].SAME_CODE == "EXIST"){
                this.common_alert('코드 값이 이미 존재합니다.', 'error')

              }else{
                this.common_alert('정상처리 되었습니다.', 'done')
                this.$emit('hideAndGetList');
              }              

            } else {
              this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
            }
          })
          .catch((err) => {
            alert(err);
          });
    },

    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },
    
  },

  computed: {
    ...mapGetters({
      userId: 'userStore/GE_USER_ID',
      userNm: 'userStore/GE_USER_MENU'
    }),
  },  
};
</script>

<style></style>
