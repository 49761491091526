<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>IVR 관리</h1>
    </div>
 
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="30px">
          <col width="120px">
          <col width="75px">
          <col width="150px">
          <col width="55px">
          <col width="150px">
          <col width="75px">
          <col width="100px">
          <col width="75px">
          <col width="100px">
          <col width="85px">
          <col width="120px">
          <col width="75px">
          <col width="210px">
          <col>
        </colgroup>
        <tr>
          
          <th><label>코드</label></th>
          <td>
            <v-text-field class="jh-form" v-model="MSG_CODE" @keyup.enter="selectList"></v-text-field>
          </td>
          <th><label>한글명</label></th>
          <td>
            <v-text-field class="jh-form" v-model="MSG_HANGL_NM" @keyup.enter="selectList"></v-text-field>
          </td>
          <th><label>영문명</label></th>
          <td>
            <v-text-field class="jh-form" v-model="MSG_ENG_NM" @keyup.enter="selectList"></v-text-field>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" id="btnSearch" @click="selectList">조회</v-btn></td>
        </tr>
      </table>            
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>IVR 리스트</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-border-blue" id="btnNew" @click="ivrAdd" >신규</v-btn>
          <v-btn class="jh-btn is-red" id="btnDelete" @click="showIvrDeleteConfirm" >삭제</v-btn>
          <v-btn class="jh-btn is-light" @click="downloadExcel('ivrListExcel', 'IVR 리스트', 'IVR 리스트')">엑셀다운로드</v-btn>
          <!-- <v-btn class="jh-btn is-light" id="btnDetail" @click="sendDetailInfo" v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')">상세</v-btn> -->
          <!-- <v-btn class="jh-btn is-fill" id="btnNewAttr" @click="groupAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnRegiGroup')">소속등록</v-btn> -->
        </div>
      </div>
       <v-data-table
                id="ivrList"
                class="jh-grid"
                height="600px"
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="100"
                item-key="MSG_CODE"
                :page.sync="page"
                :item-class="isActiveRow"
                fixed-header
                hide-default-footer
                show-select
                @page-count="pageCount = $event"
                @click:row="setListSel"
                @dblclick:row="ivrInfo"
                v-model="selectedCdList"
                no-data-text="검색된 결과가 없습니다."
            >
        </v-data-table>  

        <div class="jh-form-wrap" v-show="false">
          <v-data-table
                  id="ivrListExcel"
                  class="jh-grid"
                  height="600px"
                  :headers="gridDataHeaders"
                  :items="gridDataText"
                  :items-per-page="100000"
                  item-key="MSG_CODE"
                  :item-class="isActiveRow"
                  fixed-header
                  hide-default-footer
                  show-select
                  @click:row="setListSel"
                  @dblclick:row="ivrInfo"
                  v-model="selectedCdList"
                  no-data-text="검색된 결과가 없습니다."
              >
          </v-data-table>          
        </div>

        <div class="jh-pager">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination>
          <span class="jh-counter is-left">총 <em>{{ gridDataText.length }}</em> 건</span>
      </div>
                    
    </div>

        <v-dialog max-width="350" v-if="dialogE040110P01" v-model="dialogE040110P01" :eager="true">
            <dialog-E040110P01
                :searchCustKey = "searchCustKey"
                headerTitle="IVR 신규생성"
                @hide="hideDialog('E040110P01')"
                @hideAndGetList="hideAndGetList('E040110P01')"
            >
            </dialog-E040110P01>
        </v-dialog>    
        <v-dialog max-width="350" v-if="dialogE040110P02" v-model="dialogE040110P02" :eager="true">
            <dialog-E040110P02
                :param="detailData"
                headerTitle="IVR 상세"
                @hide="hideDialog('E040110P02')"
                @hideAndGetList="hideAndGetList('E040110P02')"
            >
            </dialog-E040110P02>
        </v-dialog>        
  </div>
</template>
  
  <script>
  import dataTables from "@/components/DataTables";
  import DialogE040110P01 from "./E040110P01.vue";
  import DialogE040110P02 from "./E040110P02.vue";
  import api from "@/store/apiUtil";
  import {mixin} from "@/mixin/mixin";
  import gibberish from "@/mixin/gibberish-aes_mixin";
  import { eventBus } from "@/store/eventBus.js";
  import { mapGetters } from 'vuex';
  
  export default {
    name: "MENU_E040110", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogE040110P01,
    DialogE040110P02,
    dataTables
  },
  data: () => ({
    dialogE040110P01: false,
    dialogE040110P02: false,
    searchVo: function (){
      this.SEARCH_PROH_WORD = "" //금칙어명
      this.USE_YN = ""//사용여부
      this.ASP_NEWCUST_KEY = "001"
    },
    formSearchData: {},
    common_code: [],
    selectedCdList: [],
    searchName: "",
    MSG_CODE: "",
    MSG_HANGL_NM: "",
    MSG_ENG_NM: "",
    gridDataHeaders: [
      { text: "checkBox", value: "data-table-select", align: "center", width: "30px", sortable: false},
      { text: "No", align: "center", value: "index", width: "60px" },
      { text: "코드", value: "MSG_CODE", align: "center", width: "160px" },
      { text: "한글명", value: "MSG_HANGL_NM", align: "center", width: "300px" },
      { text: "영문명", value: "MSG_ENG_NM", align: "center", width: "300px" },

      { text: "MSG_TP_CODE", value: "MSG_TP_CODE", align: "center" },
      { text: "MSG_DESCR_CNTS", value: "MSG_DESCR_CNTS", align: "center" },
      { text: "CNTR_USE_YN", value: "CNTR_USE_YN", align: "center" },
      { text: "COM_MSG_HANGL_NM", value: "COM_MSG_HANGL_NM", align: "center" },
      { text: "DOMN_USE_YN1", value: "DOMN_USE_YN1", align: "center" },
      { text: "DOMN_CNTS1", value: "DOMN_CNTS1", align: "center" },
      { text: "DOMN_USE_YN2", value: "DOMN_USE_YN2", align: "center" },
      { text: "DOMN_CNTS2", value: "DOMN_CNTS2", align: "center" },
      { text: "MSG_GRD_CODE", value: "MSG_GRD_CODE", align: "center" },

    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    totalVisible: 10,
    detailData: {},
    selectedRow: [],
    selectedRow2: [],
    isFormValid: true,
    searchCustKey: '',
      useYnDrop: [],

    dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '600px',
        itemKey: 'MSG_CODE',
        itemClass: 'isActiveRow',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
    },
    paginationOptions: {
        totalVisible: 10
    },
  }),
  mounted() {
  },
  methods: {
    downloadExcel(tableId, fileNm, sheetNm){
        //number,date,time,string - n,d,t,s
      var exceltypeArr = {};
     exceltypeArr[0] = "s";
      exceltypeArr[1] = "n";
      exceltypeArr[2] = "s";
      exceltypeArr[3] = "s";
      exceltypeArr[4] = "s";
      exceltypeArr[5] = "s";
      exceltypeArr[6] = "s";
      exceltypeArr[7] = "s";
      exceltypeArr[8] = "s";
      exceltypeArr[9] = "s";
      exceltypeArr[10] = "s";
      exceltypeArr[11] = "s";
      exceltypeArr[12] = "s";
      exceltypeArr[13] = "s";

        
        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },    
    ivrInfo(event, { item }) {
      console.log("item   ", item)
      this.detailData = {}
      this.detailData = item
      this.showDialog("E040110P02");
    },    
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },    
    showIvrDeleteConfirm(){
      if(this.isEmpty(this.selectedCdList)){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.common_confirm('삭제하시겠습니까?', this.ivrDelete, '', this.closeAlert, '', 'error')
    },
    async ivrDelete(){

      let deleteList = [];

      for(let i in this.selectedCdList){
        let obj = {
          MSG_CODE: this.selectedCdList[i].MSG_CODE
        };
        deleteList.push(obj)
      }

      let jsonData = {
        sendData: {
          DATA: deleteList
        }
      };


      let URLData = ""
      let HEADER_SERVICE = ""
      let HEADER_TYPE = "BIZ_SERVICE"
      let HEADER_METHOD = ""      
      URLData = "/api/phone/ivr/delete"
      HEADER_SERVICE = "phone.ivr.delete"
      HEADER_METHOD = "delete"

      await api
          .post(URLData, jsonData.sendData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            },
          })
          .then((response) => {
            this.selectedRow = [];
            this.detailData = {}
            this.selectList() 
          })
          .catch((err) => {
            alert(err);
          });


      // this.apiCall('D', jsonData.sendData)
    },    
    hideAndGetList(type){
      this.detailData = {}
      this.selectedCdList = []
      this.hideDialog(type)
      this.selectList()
    },    
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },    
    ivrAdd(){
      this.showDialog("E040110P01")
    },    
    isActiveRow(item){
      return item.MSG_CODE == this.detailData.MSG_CODE? 'is-active':'';
    },
    setListSel(item, row) {
      this.detailData = item;
    },    

    async selectList(){
      let URLData = ""
      let HEADER_SERVICE = ""
      let HEADER_TYPE = "BIZ_SERVICE"
      let HEADER_METHOD = ""      
      URLData = "/api/phone/ivr/inqire"
      HEADER_SERVICE = "phone.ivr.inqire"
      HEADER_METHOD = "list"

      await api
          .post(URLData, 
          {
            ASP_NEWCUST_KEY: "001",
            MSG_CODE: this.MSG_CODE,
            MSG_HANGL_NM: this.MSG_HANGL_NM,
            MSG_ENG_NM: this.MSG_ENG_NM,
          }, 
          {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            },
          })
          .then((response) => {
            console.log(response.data.DATA);
            this.gridDataText = response.data.DATA;
            for(var i=0; i<this.gridDataText.length;i++){
              this.gridDataText[i]["index"]= i+1;
            }           
          })
          .catch((err) => {
            alert(err);
          });

    },    
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },    
  },
  computed: {

  },
  

  created() { 
        this.dataTableOptions.headers = this.gridDataHeaders;
        this.dataTableOptions.items = this.gridDataText;
    }
};
</script>
<style>


</style>